<template>
    <el-row :gutter="10">
        <el-col :span="8">
            <el-divider>{{ $t("lang.工程管理") }}</el-divider>
            <div style="display:flex;flex-direction:row-reverse">
                <el-button-group>
                    <el-button type="primary" @click="add1">{{ $t("lang.增加") }}</el-button>
                    <el-button type="info" @click="edit1">{{ $t("lang.编辑") }}</el-button>
                    <el-button type="danger" @click="del1">{{ $t("lang.删除") }}</el-button>
                </el-button-group>
            </div>
            <ve-table style="margin-top:10px;" v-loading="loading1" ref="table1" borderY :columns="columns1" :table-data="tableData1" row-key-field-name="id" :event-custom-option="eventCustomOption1"></ve-table>
            <ve-pagination :total="total1" :pageIndex="searchObj1.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="pageNumberChange1"></ve-pagination>
        </el-col>
        <el-col :span="8">
            <el-divider>{{ $t("lang.测位管理") }}</el-divider>
            <div style="display:flex;flex-direction:row-reverse">
                <el-button-group>
                    <el-button type="primary" @click="add2">{{ $t("lang.增加") }}</el-button>
                    <el-button type="info" @click="edit2">{{ $t("lang.编辑") }}</el-button>
                    <el-button type="danger" @click="del2">{{ $t("lang.删除") }}</el-button>
                </el-button-group>
            </div>
            <ve-table v-loading="loading2" style="margin-top:10px;" ref="table2" borderY :columns="columns2" :table-data="tableData2" row-key-field-name="id" :event-custom-option="eventCustomOption2"></ve-table>
            <el-empty v-show="tableData2.length == 0" :description="$t('lang.无数据')"></el-empty>
            <ve-pagination :total="total2" :pageIndex="searchObj2.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="pageNumberChange2"></ve-pagination>
        </el-col>
        <el-col :span="8">
            <el-divider>{{ $t("lang.测点管理") }}</el-divider>
            <div style="display:flex;flex-direction:row-reverse">
                <el-button-group>
                    <el-button type="primary" @click="add3">{{ $t("lang.增加") }}</el-button>
                    <el-button type="info" @click="edit3">{{ $t("lang.编辑") }}</el-button>
                    <el-button type="danger" @click="del3">{{ $t("lang.删除") }}</el-button>
                </el-button-group>
            </div>
            <ve-table v-loading="loading3" style="margin-top:10px;" ref="table3" borderY :columns="columns3" :table-data="tableData3" row-key-field-name="id" :event-custom-option="eventCustomOption3"></ve-table>
            <el-empty v-show="tableData3.length == 0" :description="$t('lang.无数据')"></el-empty>
            <ve-pagination :total="total3" :pageIndex="searchObj3.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="pageNumberChange3"></ve-pagination>
        </el-col>
        <el-dialog :visible.sync="visible1" :close-on-click-modal="false" :show-close="false" width="30%">
            <template #title>
                <div style="border-bottom:1px solid #DCDFE6;"><i class="el-icon-s-help" style="color:#409EFF"></i>{{
                    $t("lang.工程信息") }}</div>
            </template>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit1">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible1 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
            <el-form :model="model1" ref="form1" label-width="150px" label-position="left" :rules="rules1">
                <el-form-item :label="$t('lang.工程名称')" prop="projectName">
                    <el-input v-model="model1.projectName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.开始时间')" prop="beginDate">
                    <el-date-picker v-model="model1.beginDate" type="datetime" :placeholder="$t('lang.选择日期')" style="width:100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('lang.结束时间')" prop="endDate">
                    <el-date-picker v-model="model1.endDate" type="datetime" :placeholder="$t('lang.选择日期')" style="width:100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('lang.备注')" prop="remark">
                    <el-input v-model="model1.remark"></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="visible2" :close-on-click-modal="false" :show-close="false" width="30%">
            <template #title>
                <div style="border-bottom:1px solid #DCDFE6;"><i class="el-icon-s-help" style="color:#409EFF"></i>{{
                    $t("lang.测位信息") }}</div>
            </template>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit2">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible2 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
            <el-form :model="model2" ref="form2" label-width="150px" label-position="left" :rules="rules2">
                <el-form-item :label="$t('lang.测位名称')" prop="name">
                    <el-input v-model="model2.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.备注')" prop="remark">
                    <el-input v-model="model2.remark"></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="visible3" :close-on-click-modal="false" :show-close="false" width="30%" @open="onOpen3">
            <template #title>
                <div style="border-bottom:1px solid #DCDFE6;"><i class="el-icon-s-help" style="color:#409EFF"></i>{{
                    $t("lang.测点信息") }}</div>
            </template>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit3">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible3 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
            <el-form :model="model3" ref="form3" label-width="150px" label-position="left" :rules="rules3">
                <el-form-item :label="$t('lang.测点名称')" prop="name">
                    <el-autocomplete v-model="model3.name" :fetch-suggestions="querySearch3" :placeholder="$t('lang.请输入测点名称')" style="width:100%;"></el-autocomplete>
                </el-form-item>
                <el-form-item :label="$t('lang.设备序列号')" prop="serialNumber">
                    <el-select v-model="model3.serialNumber" :placeholder="$t('lang.请选择设备')" value-key="serialNumber" style="width:100%;" @change="handleChange">
                        <el-option v-for="(item, index) in myCompanyDevice" :key="index" :label="item.serialNumber + '(' + item.remark + ')'" :value="item.serialNumber"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('lang.设备通道')" prop="passageway">
                    <el-select v-model="model3.passageway" :placeholder="$t('lang.请选择通道')" style="width:100%;">
                        <el-option v-for="(item, index) in tongDaoCount" :key="index" :label="'T' + (index + 1)" :value="'T' + (index + 1)"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('lang.备注')" prop="remark">
                    <el-input v-model="model3.remark"></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-row>
</template>

<script>
import Vue from "vue";

// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";

Vue.use(VueEasytable);

export default {
    data() {
        let that = this;
        return {
            visible1: false,
            visible2: false,
            visible3: false,
            searchObj1: {
                pageIndex: 1,
                pageSize: 10,
            },
            loading1: false,
            model1: {},
            rules1: {
                projectName: [{ required: true, message: that.$t("lang.请输入工程名称"), trigger: "blur" }],
                beginDate: [{ required: true, message: that.$t("lang.请输入开始日期"), trigger: "blur" }],
                endDate: [{ required: true, message: that.$t("lang.请输入结束日期"), trigger: "blur" }],
            },
            total1: 0,
            columns1: [
                { field: "projectName", key: "projectName", title: that.$t("lang.工程名称") },
                { field: "beginDate", key: "beginDate", title: that.$t("lang.开始日期") },
                { field: "endDate", key: "endDate", title: that.$t("lang.结束日期") },
                {
                    field: "", key: "a", title: that.$t("lang.操作"), renderBodyCell: ({ row }) => {
                        console.log(row);
                        return (<el-button type="primary" icon="el-icon-printer" size="mini" on-click={() => open(process.env.VUE_APP_TurnUrl + "Print/WXCW03_GCGL_Export?projectId=" + row.id)} circle></el-button>);
                    }
                },
            ],
            tableData1: [],
            eventCustomOption1: {
                bodyRowEvents: ({ row }) => {
                    let that = this;
                    return {
                        click() {
                            that.selectRow1 = row;
                            that.searchObj2.pageIndex = 1;
                            that.load2();
                        },
                    };
                },
            },
            selectRow1: {},
            loading2: false,
            searchObj2: {
                pageIndex: 1,
                pageSize: 10,
            },
            model2: {},
            rules2: {
                name: [{ required: true, message: that.$t("lang.请输入测位名称"), trigger: "blur" }],
            },
            total2: 0,
            columns2: [
                { field: "name", key: "name", title: that.$t("lang.测位名称") },
                { field: "remark", key: "remark", title: that.$t("lang.备注") },
            ],
            tableData2: [],
            eventCustomOption2: {
                bodyRowEvents: ({ row }) => {
                    let that = this;
                    return {
                        click() {
                            that.selectRow2 = row;
                            that.searchObj3.pageIndex = 1;
                            that.load3();
                        },
                    };
                },
            },
            selectRow2: {},
            loading3: false,
            searchObj3: {
                pageIndex: 1,
                pageSize: 10,
            },
            model3: {},
            rules3: {
                name: [{ required: true, message: that.$t("lang.请输入测点名称"), trigger: "change" }],
                serialNumber: [{ required: true, message: that.$t("lang.请选择设备"), trigger: "change" }],
                passageway: [{ required: true, message: that.$t("lang.请选择通道"), trigger: "change" }],
            },
            total3: 0,
            columns3: [
                { field: "name", key: "name", title: that.$t("lang.测点名称") },
                { field: "serialNumber", key: "serialNumber", title: that.$t("lang.测点设备") },
                { field: "passageway", key: "passageway", title: that.$t("lang.测点通道") },
            ],
            tableData3: [],
            eventCustomOption3: {
                bodyRowEvents: ({ row }) => {
                    let that = this;
                    return {
                        click() {
                            that.selectRow3 = row;
                        },
                    };
                },
            },
            selectRow3: {},
            suggestData: [{ value: that.$t("lang.表层") }, { value: that.$t("lang.中上") }, { value: that.$t("lang.中") }, { value: that.$t("lang.中下") }, { value: that.$t("lang.底层") }, { value: that.$t("lang.环境") }],
            myCompanyDevice: [],
            tongDaoCount: 8,
        };
    },
    methods: {
        handleChange(v) {
            let that = this;
            let selectSerialNumberObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == v; });
            if (selectSerialNumberObject.deviceType == "WXCW80") {
                that.tongDaoCount = 4;
            }
            else {
                that.tongDaoCount = 8;
            }
        },
        add1() {
            let that = this;
            that.visible1 = true;
            that.model1 = {};
            if (that.$refs["form1"]) {
                that.$refs["form1"].resetFields();
            }
        },
        edit1() {
            let that = this;
            if (that.$refs["form1"]) {
                that.$refs["form1"].resetFields();
            }
            if (that.selectRow1.id) {
                that.visible1 = true;
                that.axios.post("WXCW03/GetGCGL", { onlyInt: that.selectRow1.id }).then((response) => {
                    that.model1 = response.data.data;
                });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择项目") });
            }
        },
        del1() {
            let that = this;
            if (that.selectRow1.id) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios.post("WXCW03/DelGCGL", { onlyInt: that.selectRow1.id }).then(function () {
                            that.$message({ type: "success", message: that.$t("lang.删除成功") });
                            that.load1();
                        });
                    })
                    .catch(() => { });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择项目") });
            }
        },
        load1() {
            let that = this;
            that.loading1 = true;
            that.selectRow1 = {};
            that.$refs["table1"].setHighlightRow({ rowKey: "" });
            that.axios.post("WXCW03/GetMyProject", that.searchObj1).then(function (response) {
                console.log(response);
                that.loading1 = false;
                that.tableData1 = response.data.data.data;
                that.total1 = response.data.data.total;
                if (that.tableData1.length > 0) {
                    that.$refs["table1"].setHighlightRow({
                        rowKey: that.tableData1[0].id,
                    });
                    that.selectRow1 = that.tableData1[0];
                    that.searchObj2.pageIndex = 1;
                    that.load2();
                }
            });
        },
        submit1() {
            let that = this;
            that.$refs["form1"].validate((valid) => {
                if (valid) {
                    that.axios.post("WXCW03/SaveGCGL", that.model1).then((response) => {
                        if (response.data.code == 101) {
                            that.visible1 = false;
                            that.$message({ type: "success", message: that.$t("lang.保存成功") });
                            that.load1();
                        }
                    });
                }
            });
        },
        pageNumberChange1(pageIndex) {
            let that = this;
            that.searchObj1.pageIndex = pageIndex;
            that.load1();
        },
        add2() {
            let that = this;
            that.visible2 = true;
            that.model2 = {};
            if (that.$refs["form2"]) {
                that.$refs["form2"].resetFields();
            }
        },
        edit2() {
            let that = this;
            if (that.$refs["form2"]) {
                that.$refs["form2"].resetFields();
            }
            if (that.selectRow2.id) {
                that.visible2 = true;
                that.axios.post("WXCW03/GetOneCWGL", { onlyInt: that.selectRow2.id }).then((response) => {
                    that.model2 = response.data.data;
                });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择测位") });
            }
        },
        del2() {
            let that = this;
            if (that.selectRow2.id) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios.post("WXCW03/DelCWGL", { onlyInt: that.selectRow2.id }).then(function () {
                            that.$message({ type: "success", message: that.$t("lang.删除成功") });
                            that.load2();
                        });
                    })
                    .catch(() => { });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择测位") });
            }
        },
        load2() {
            let that = this;
            that.loading2 = true;
            that.selectRow2 = {};
            that.$refs["table2"].setHighlightRow({ rowKey: "" });
            that.searchObj2.docId = that.selectRow1.id;
            that.axios.post("WXCW03/GetCWGL", that.searchObj2).then((response) => {
                that.loading2 = false;
                that.tableData2 = response.data.data.data;
                that.total2 = response.data.data.total;
                if (that.tableData2.length > 0) {
                    that.$refs["table2"].setHighlightRow({
                        rowKey: that.tableData2[0].id,
                    });
                    that.selectRow2 = that.tableData2[0];
                }
                that.searchObj3.pageIndex = 1;
                that.load3();
            });
        },
        submit2() {
            let that = this;
            that.$refs["form2"].validate((valid) => {
                if (valid) {
                    if (that.selectRow1.id) {
                        that.model2.docId = that.selectRow1.id;
                        that.axios.post("WXCW03/SaveCWGL", that.model2).then((response) => {
                            if (response.data.code == 101) {
                                that.visible2 = false;
                                that.$message({ type: "success", message: that.$t("lang.保存成功") });
                                that.load2();
                            }
                        });
                    } else {
                        that.$message({ type: "error", message: that.$t("lang.请选择工程") });
                    }
                }
            });
        },
        pageNumberChange2(pageIndex) {
            let that = this;
            that.searchObj2.pageIndex = pageIndex;
            that.load2();
        },

        add3() {
            let that = this;
            that.visible3 = true;
            that.model3 = {};
            if (that.$refs["form3"]) {
                that.$refs["form3"].resetFields();
            }
        },
        edit3() {
            let that = this;
            if (that.$refs["form3"]) {
                that.$refs["form3"].resetFields();
            }
            if (that.selectRow3.id) {
                that.visible3 = true;
                that.axios.post("WXCW03/GetOneCDGL", { onlyInt: that.selectRow3.id }).then((response) => {
                    that.model3 = response.data.data;
                    //编辑的时候控制通道数量
                    let selectSerialNumberObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == that.model3.serialNumber; });
                    if (selectSerialNumberObject.deviceType == "WXCW80") {
                        that.tongDaoCount = 4;
                    }
                    else {
                        that.tongDaoCount = 8;
                    }
                });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择测点") });
            }
        },
        del3() {
            let that = this;
            if (that.selectRow3.id) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios.post("WXCW03/DelCDGL", { onlyInt: that.selectRow3.id }).then(function () {
                            that.$message({ type: "success", message: that.$t("lang.删除成功") });
                            that.load3();
                        });
                    })
                    .catch(() => { });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择测点") });
            }
        },
        load3() {
            let that = this;
            that.loading3 = true;
            that.selectRow3 = {};
            that.$refs["table3"].setHighlightRow({ rowKey: "" });
            that.searchObj3.docId = that.selectRow2.id;
            that.axios.post("WXCW03/GetCDGL", that.searchObj3).then((response) => {
                that.loading3 = false;
                that.tableData3 = response.data.data.data;
                that.total3 = response.data.data.total;
            });
        },
        submit3() {
            let that = this;
            that.$refs["form3"].validate((valid) => {
                if (valid) {
                    if (that.selectRow2.id) {
                        that.model3.docId = that.selectRow2.id;
                        that.axios.post("WXCW03/SaveCDGL", that.model3).then((response) => {
                            if (response.data.code == 101) {
                                that.visible3 = false;
                                that.$message({ type: "success", message: that.$t("lang.保存成功") });
                                that.load3();
                            }
                        });
                    } else {
                        that.$message({ type: "error", message: that.$t("lang.请选择测位") });
                    }
                }
            });
        },
        pageNumberChange3(pageIndex) {
            let that = this;
            that.searchObj3.pageIndex = pageIndex;
            that.load3();
        },
        querySearch3(queryString, cb) {
            var results = queryString
                ? this.suggestData.filter((ele) => {
                    return ele.value.indexOf(queryString) >= 0;
                })
                : this.suggestData;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        onOpen3() {
            let that = this;
            that.axios
                .post("WXCW03/GetMyCompanyDevice1", {
                    deviceType: "WXCW",
                })
                .then((response) => {
                    that.myCompanyDevice = response.data.data;

                });
        },
    },
    mounted() {
        let that = this;
        that.load1();
    },
};
</script>

<style scoped></style>